<template>
    <button @click="action" :disabled="desactivar">{{ texto }}</button>
</template>

<script>
export default {
    props: {
        texto: String,
        desactivar: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        action(){
            this.$emit('accion')
        }
    }
}
</script>

<style>

</style>